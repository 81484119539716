import { Component, OnInit, Input } from '@angular/core';
import {
  BalanceQuery,
  Barcode,
  BarcodeQuery,
  config,
  MemberCardQuery,
  MemberDetails,
  MemberQuery,
  ProductCodeQuery,
} from '@fgb/core';
import { BarcodeService } from '@fgb/core';
import { Observable } from 'rxjs';
import { MemberCard } from '@fgb/core';

@Component({
  selector: 'fgb-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss'],
})
export class BarcodeComponent implements OnInit {
  @Input() memberId: string;
  @Input() isWalletPage: boolean = false;
  @Input() walletLink: string = '/rewards/wallet';
  @Input() showBarcode: boolean = false;
  @Input() primaryTheme: boolean = false;
  barcode$: Observable<Barcode>;
  card$: Observable<MemberCard | undefined>;
  eCash$: Observable<number | undefined>;
  member$: Observable<MemberDetails | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  season: boolean;

  constructor(
    private barcodeService: BarcodeService,
    private barcodeQuery: BarcodeQuery,
    private memberCardQuery: MemberCardQuery,
    private memberDetailsQuery: MemberQuery,
    private memberQuery: MemberQuery,
    private productCodeQuery: ProductCodeQuery,
    private balanceQuery: BalanceQuery
  ) {}

  ngOnInit() {
    this.barcodeService.fetchBarcode().toPromise();
    this.barcode$ = this.barcodeQuery.select();
    this.member$ = this.memberDetailsQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectCurrentCard();
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.season = this.productCodeQuery.currentUserIsProductCode(['Season']);
    this.eCash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
  }
}
