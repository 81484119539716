import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EcashService, config } from '@fgb/core';
import { ProviderUiActionResponse } from '@fgb/core';
import { CallBackModel } from '@fgb/core';

@Component({
  selector: 'fgb-ecash-result',
  templateUrl: 'ecash-result-page.component.html'
})
export class EcashResultPageComponent implements OnInit {
  response = new ProviderUiActionResponse();

  constructor(private ecashService: EcashService, private router: Router) {}

  ngOnInit() {
    this.processCallBack();
  }

  processCallBack() {
    this.ecashService.callBack(new CallBackModel('', config.baseApiUrl + this.router.url)).subscribe(response => {
      if (response.Success) {
        this.router.navigateByUrl('payment/success');
      } else {
        this.router.navigateByUrl('payment/failure');
      }
    });
  }
}
