import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'fgb-payment-nav',
  templateUrl: './payment-nav.component.html',
  styleUrls: ['./payment-nav.component.scss'],
})
export class PaymentNavComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
