<div class="container">
  <div class="card mx-lg-auto">
    <div class="card-body text-center py-3">
      <div class="card-heading d-flex align-items-center">
        <span class="icon material-icons me-2 text-danger" aria-hidden="true">add_card</span>
        <span class="text-danger font-size-20 font-heading fw-bold text-uppercase">{{
          'ecash.failure.page.title' | translate
        }}</span>
      </div>
      <div class="mt-4 sub-heading-modal">
        <div class="text-center">{{ 'ecash.failure.page.card.message' | translate }}</div>
      </div>
      <div class="btn-container mt-2 d-block">
        <button [routerLink]="['/payment']" type="button" class="btn text-white btn-danger mx-2 mt-4 fw-bold">
          {{ 'ecash.failure.page.close.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
