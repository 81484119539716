import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import {
  config,
  MemberDetails,
  MemberQuery,
  BalanceQuery,
  MemberCard,
  MemberCardQuery,
  NotificationCenterQuery,
  Cart,
  Card,
  CardService,
  TMAuthService,
} from '@fgb/core';
import { Observable, Subject } from 'rxjs';
import { tap, takeUntil } from 'rxjs/operators';
import { Populations } from 'src/app/shared/utilities/populations';
import { ProductCode } from 'src/app/shared/utilities/product-code';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'fgb-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss'],
})
export class NavBarComponent implements OnInit, OnDestroy {
  populations = Populations;
  productcode = ProductCode;
  eCash$: Observable<number | undefined>;
  memberDetails$: Observable<MemberDetails | undefined>;
  combinedCard$: Observable<number | undefined>;
  loyaltyPointsCard$: Observable<number | undefined>;
  card$: Observable<MemberCard | undefined>;
  unseenNotificationCount$: Observable<number>;
  isCollapsed = false;
  name$: Observable<string>;
  selectedMember: string;
  cards$: Observable<Card[]>;
  totalItems$: Observable<number>;
  basketPointsTotal$: Observable<number | undefined>;
  items$: Observable<Cart[] | undefined>;
  currentLanguage: string = '';
  private _destroyed$ = new Subject<void>();

  constructor(
    private translate: TranslateService,
    public router: Router,
    public cardService: CardService,
    public memberQuery: MemberQuery,
    private balanceQuery: BalanceQuery,
    private memberCardQuery: MemberCardQuery,
    private notificationQuery: NotificationCenterQuery,
    private tmAuthService: TMAuthService
  ) {}

  useLanguage(language: string) {
    this.translate.use(language);
  }

  ngOnInit() {
    this.loyaltyPointsCard$ = this.balanceQuery.selectPurse(config.purseConfig.virtualLoyalty);
    this.combinedCard$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.memberDetails$ = this.memberQuery.selectMemberDetails();
    this.card$ = this.memberCardQuery.selectObservedMemberCard();
    this.unseenNotificationCount$ = this.notificationQuery.unseenNotificationsCount$;
    this.eCash$ = this.balanceQuery.selectPurse(config.purseConfig.combined);
    this.initLcid();
  }

  ngOnDestroy() {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

  initLcid() {
    this.memberQuery.userContext$.pipe(takeUntil(this._destroyed$)).subscribe((user) => {
      if (user && user.lcid) {
        if (user.lcid === '2057') {
          this.currentLanguage = 'EN';
        } else if (user.lcid === '1036') {
          this.currentLanguage = 'FR';
        } else if (user.lcid === '1037') {
          this.currentLanguage = 'HE';
        } else if (user.lcid === '1033') {
          this.currentLanguage = 'US';
        } else if (user.lcid === '4105') {
          this.currentLanguage = 'EN-CA';
        } else if (user.lcid === '3084') {
          this.currentLanguage = 'FR-CA';
        }
      }
    });
  }

  logout() {
    this.tmAuthService
      .logout()
      .pipe(tap(() => this.router.navigate(['/login'])))
      .toPromise();
  }

  closeDropDown(dropdown: NgbDropdown) {
    dropdown.close();
  }
}
