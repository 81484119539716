<ng-container *ngIf="memberCard$ | async as memberCard; else loadingRef">
  <h2 class="accessibility-only">Member Card</h2>
  <div class="member-card rounded-4" [ngClass]="season ? 'bg-primary' : 'bg-secondary'">
    <div class="d-flex flex-column justify-content-between h-100">
      <!--Name and ID-->
      <div class="card-id text-start" [ngClass]="season ? 'text-secondary' : 'text-white'">
        <p class="mb-2 card-name text-uppercase" *ngIf="memberDetails$ | async as memberDetails">
          {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
        </p>
        <div class="d-flex font-size-12">
          <div>{{ memberCard.ProductCode }} {{ 'product.code.description' | translate }}</div>
        </div>
      </div>
      <!--Discounts-->
      <div class="d-flex align-items-center position-relative" [ngClass]="season ? 'text-secondary' : 'text-white'">
        <div class="text-start pe-4">
          <label class="font-size-14 mb-0">{{ memberCard.RetailDiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
        </div>

        <div [ngClass]="season ? 'border-right-season' : 'border-right-premium'"></div>

        <div class="text-start px-4">
          <label class="font-size-14 mb-0">{{ memberCard.DiscountLevel }}%</label>
          <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
        </div>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.payment'">
          <div [ngClass]="season ? 'border-right-season' : 'border-right-premium'"></div>

          <div class="text-start ps-4">
            <label class="font-size-14 mb-0"> {{ eCash$ | async | FortressCurrencyPipe }}</label>
            <div class="font-size-12">{{ 'member.card.payment' | translate }}</div>
          </div>
        </ng-container>

        <!--Logo-->
        <img alt="" class="logo" src="assets/images/logo.png" draggable="false" />
      </div>
    </div>
  </div>
  <fgb-ecash-button></fgb-ecash-button>
</ng-container>

<ng-template #loadingRef>
  <fgbcl-loading></fgbcl-loading>
</ng-template>
