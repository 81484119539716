<div *ngIf="lottoItem; else noPurchase" class="container">
  <div class="d-block d-lg-flex shadow border-0 rounded overflow-hidden mt-lg-5 mt-3">
    <div class="item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 p-3">
      <div>
        <div class="text-success mb-0 fw-bold text-uppercase font-size-14 text-center">
          {{ 'lotto.entry.successful' | translate }}
        </div>
        <hr class="mt-4 mx-5 mb-2" />
        <div class="row mt-5 mb-5">
          <div class="col-lg-1 col-2 d-flex justify-content-left">
            <img class="purchase-success-image shadow rounded"
              src="{{ lottoItem.ImageName | contentImage: '6':'lotto' }}" alt="{{ lottoItem.ImageName }}"
              [useDefault]="true" [loyaltyType]="'lotto'" draggable="false" />
          </div>
          <div class="col-lg-10 col-10">
            <div class="pl-lg-3 ps-1">
              <div class="font-size-12 mb-1">{{ lottoItem.Title }}</div>
              <div class="row">
                <div class="col-6 col-lg-4">
                  <div class="font-size-12 mb-0">
                    <span class="fw-bold pe-2">{{ 'lotto.points.used' | translate }}</span>
                    <span>{{ totalPrice | number }}</span>
                  </div>
                </div>
                <div class="col-6 col-lg-4">
                  <div class="font-size-12 mb-0">
                    <span class="fw-bold pe-2">{{ 'lotto.entries' | translate }}</span>
                    <span>{{ quantity | number }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-between border-0">
        <a class="btn btn-primary confirm-btn fw-bold" [routerLink]="['/rewards/marketplace']">{{
          'rewards.back.to.rewards' | translate
          }}</a>
        <a class="btn btn-wallet confirm-btn fw-bold" [routerLink]="['/rewards/wallet']">{{
          'rewards.back.to.wallet' | translate
          }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">No raffles found...</div>
</ng-template>