<h2 class="accessibility-only">Code Redemption</h2>
<div class="card border-0 shadow">
  <h4 class="card-header fw-bold bg-white border-0 px-2 pb-0 pt-2 font-size-12 text-center">
    {{ 'voucher.title' | translate }}
  </h4>
  <div class="card-body pt-2 d-flex flex-column align-items-center">
    <input type="text" [(ngModel)]="voucherCode" placeholder="ENTER CODE HERE"
      class="form-control text-center border-0 bg-muted code-input" (keyup.enter)="redeemVoucher()" />
    <div class="result-box">
      <div class="result mt-0">
        <fgbcl-errors></fgbcl-errors>
        <div class="font-italic" *ngIf="redemptionResult">
          {{ redemptionResult }}
        </div>
      </div>
      <button class="btn btn-primary form-control voucher-submit bg-primary text-white border-0 fw-bold font-size-14"
        (click)="redeemVoucher()" [disabled]="disableButton">
        {{ 'voucher.button' | translate }}
      </button>
    </div>
  </div>
</div>