<div class="container">
  <div class="card mx-lg-auto">
    <div class="card-body text-center py-3">
      <div class="card-heading d-flex align-items-center">
        <span class="text-success font-size-20 font-heading fw-bold text-uppercase">{{
          'ecash.success.page.title' | translate
        }}</span>
      </div>
      <div class="mt-4 sub-heading-modal">
        <div class="text-center">{{ 'ecash.success.page.card.added' | translate }}</div>
      </div>

      <div class="success-animation mt-3">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
      </div>

      <div class="btn-container mt-2 d-block">
        <button [routerLink]="['/payment']" type="button" class="btn text-white btn-danger mt-4 fw-bold">
          {{ 'ecash.success.page.close.button' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
