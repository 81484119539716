import { Component, OnInit } from '@angular/core';
import { AuctionService, LottoService, MarketplaceService } from '@fgb/core';
import { firstValueFrom, zip } from 'rxjs';

@Component({
  selector: 'fgb-rewards-page',
  templateUrl: './rewards-page.component.html',
  styleUrls: ['./rewards-page.component.scss'],
})
export class RewardsPageComponent implements OnInit {
  constructor(
    private auctionService: AuctionService,
    private lottoService: LottoService,
    private marketplaceService: MarketplaceService
  ) {}

  ngOnInit() {
    firstValueFrom(
      zip(this.auctionService.fetchAuctions(), this.lottoService.fetchLottos(), this.marketplaceService.fetchMarketplaceItems())
    );
  }
}
