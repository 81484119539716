<ng-container *ngIf="cardToken$ | async as cardToken; else addCard">
  <div
    class="ecash-button d-flex justify-content-between align-item-center bg-secondary"
    [ngClass]="{
      'bg-frozen border-frozen': cardToken.Frozen
    }"
  >
    <div class="ecash-text fw-bold text-white">
      {{ 'member.card.ecash' | translate }}
    </div>

    <div class="card-token-number text-white">
      ({{ cardToken.CardNumber.substring(12) }})

      <img alt="" class="card-type-image px-2" src="{{ cardToken.CardType | cardTypeImageUrl }}" />

      <a class="d-flex text-white" [ngClass]="{ 'text-white': cardToken.Frozen }" [routerLink]="'/payment'">
        <span class="icon material-icons text-white">chevron_right</span>
      </a>
    </div>
  </div>
</ng-container>

<ng-template #addCard>
  <div class="ecash-button d-flex justify-content-between align-item-center bg-secondary">
    <div class="ecash-text fw-bold text-white">{{ 'member.card.ecash' | translate }}</div>
    <a class="btn d-flex align-item-center text-white p-0" [routerLink]="'/payment'">
      <span class="icon material-icons pe-2">add_card</span>
      <span class="font-size-14 add-card-text">{{ 'member.card.ecash.add' | translate }}</span>
      <span class="icon material-icons ps-1">chevron_right</span>
    </a>
  </div>
</ng-template>
