<div class="full-page w-100 d-md-flex d-block px-lg-0 px-2 bg-secondary m-0">
  <div class="login-page-div d-flex flex-column align-items-center w-100 h-100">
    <div class="login-page-logo d-flex align-items-center mt-2 mb-lg-4 mb-1">
      <img alt="" class="logo" src="assets/images/logo-words-white.png" draggable="false" />
    </div>
    <div class="container d-flex flex-column justify-content-center align-items-center bg-white rounded-4">
      <div class="row h-100">
        <div class="col-12 p-lg-4 p-3">
          <router-outlet></router-outlet>
        </div>
      </div>
    </div>
  </div>
</div>
