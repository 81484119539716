import { Component, OnInit, Input } from '@angular/core';
import { ProviderName, WorksheetItem, WorksheetService, WorksheetType } from '@fgb/core';
@Component({
  selector: 'fgb-worksheet-item',
  templateUrl: './worksheet-item.component.html',
  styleUrls: ['./worksheet-item.component.scss'],
})
export class WorksheetItemComponent implements OnInit {
  @Input() worksheetItem: WorksheetItem;
  @Input() worksheetId: number;

  worksheetType = WorksheetType;
  providerName = ProviderName;

  constructor(private worksheetService: WorksheetService) {}

  ngOnInit() {}

  public getDownload(worksheetId: number, worksheetItemId: number) {
    this.worksheetService
      .downloadWorksheet(worksheetId, worksheetItemId)
      .toPromise()
      .then((response) => {
        if (!response) {
          return
        }
        const newBlob = new Blob([response], { type: response.type });
        const anchor = window.document.createElement('a');
        anchor.href = window.URL.createObjectURL(newBlob);
        // TODO: Return worksheet item download name
        anchor.download = worksheetItemId.toString();
        document.body.appendChild(anchor);
        anchor.click();
        window.URL.revokeObjectURL(anchor.href);
        document.body.removeChild(anchor);
      });
  }
}
