<div class="card shadow border-0 p-3 mt-3">
  <div class="row mx-0 mb-1">
    <label class="checkbox-switch d-block position-relative me-3">
      <input type="checkbox" [checked]="group.Active"
        (change)="updateNotificationGroup(group.NotificationGroupId, $event.target.checked, group.EntityNotificationGroupId)" />
      <div class="slider round"></div>
    </label>
    <label class="font-size-16">{{ group.DisplayText }}</label>
    <label class="font-size-16">{{ group.Description }}</label>
  </div>
</div>