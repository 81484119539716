<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container px-0">
    <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
  </div>
</ng-container>
<div class="d-block d-lg-none">
  <fgb-payment-nav></fgb-payment-nav>
</div>

<div class="container d-none d-lg-block">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/payment/result'"
    [failureUrl]="'/payment/failure'"
    [cardIssuerProvider]="'card.issuer.provider' | translate"
  ></fgbcl-card-token-list>

  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>
<div class="container d-block d-lg-none">
  <fgbcl-card-token-list
    [regex]="regex"
    [provider]="provider"
    [redirectUrl]="'/payment/result'"
    [failureUrl]="'/payment/failure'"
    [cardIssuerProvider]="'card.issuer.provider' | translate"
  ></fgbcl-card-token-list>
  <fgbcl-ecash-description></fgbcl-ecash-description>
</div>

<div class="container d-none d-lg-block mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
