<ng-container>
  <div class="banner" role="banner"></div>
</ng-container>

<div class="container">
  <div class="mt-4 rounded-4 border">
    <fgbcl-terms-and-conditions [type]="termsAndConditionsType" [defaultTerms]="defaultTemplate"></fgbcl-terms-and-conditions>
  </div>
</div>

<ng-template #defaultTemplate>
  <p class="terms-section-content text-start mb-3">Loading...</p>
</ng-template>
