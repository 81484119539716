<div class="mixed-nav card-member d-block d-lg-none order-1 pt-1 bg-primary">
  <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
</div>

<div class="d-block d-lg-none">
  <fgb-payment-nav></fgb-payment-nav>
</div>

<div class="container mt-4">
  <fgb-transaction-history
    [transactionTypes]="[transactionTypes.ecash, transactionTypes.payment]"
    [ecashView]="true"
  ></fgb-transaction-history>
</div>
