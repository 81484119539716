import { Component, OnInit } from '@angular/core';
import { CardTokenService } from '@fgb/core';

@Component({
  templateUrl: 'ecash-success-page.component.html',
  styleUrls: ['ecash-success-page.component.scss'],
  selector: 'fgb-ecash-success',
})
export class EcashSuccessPageComponent implements OnInit {
  constructor(private cardTokenService: CardTokenService) { }
  ngOnInit() {
    this.cardTokenService.fetchCardTokens(true).toPromise();
    this.cardTokenService.fetchDefaultCardToken().toPromise();
  }
}
