import { Component, OnInit } from '@angular/core';
import { TermsAndConditionsType } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';

@Component({
  selector: 'fgb-terms-page',
  templateUrl: './terms-page.component.html',
  styleUrls: ['./terms-page.component.scss'],
})
export class TermsPageComponent implements OnInit {
  termsAndConditionsType = TermsAndConditionsType.General;
  screenType = ScreenType;

  constructor() {}

  ngOnInit() {}
}
