<nav class="nav nav-secondary account-details-nav">
  <!--Home-->
  <a
    class="nav-item"
    [routerLink]="['/']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.home.screenreader' | translate : { fgbdefault: 'Move to home page' } }}"
  >
    <label>{{ 'nav.home' | translate }}</label>
  </a>
  <!--Challenges-->
  <a
    class="nav-item"
    [routerLink]="['/challenges']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.challenges.screenreader' | translate : { fgbdefault: 'Move to challenges page' } }}"
  >
    <label>{{ 'nav.challenges' | translate }}</label>
  </a>
  <!--Challenges-->
  <a
    class="nav-item"
    [routerLink]="['/rewards/raffles']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.prize.draws.screenreader' | translate : { fgbdefault: 'Move to Prize Draws page' } }}"
  >
    <label>{{ 'nav.prize.draws' | translate }}</label>
  </a>
  <!--Wallet-->
  <a
    class="nav-item d-lg-block d-none"
    [routerLink]="['/rewards/wallet']"
    [routerLinkActive]="'active'"
    [routerLinkActiveOptions]="{ exact: true }"
    attr.aria-label="{{ 'account.nav.wallet.screenreader' | translate : { fgbdefault: 'Move to wallet page' } }}"
  >
    <label>{{ 'nav.wallet' | translate }}</label>
  </a>
</nav>
