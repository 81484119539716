<ng-container>
  <div class="d-none d-lg-block">
    <div class="banner" role="banner"></div>
  </div>
</ng-container>

<div class="container px-0 d-lg-none">
  <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
</div>

<fgb-account-nav></fgb-account-nav>

<div class="container mt-3">
  <fgbcl-lottos-list [isList]="true" [defaultSort]="0"></fgbcl-lottos-list>
</div>
