<h1 class="accessibility-only">Badges</h1>

<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container px-0">
    <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<fgb-account-nav></fgb-account-nav>

<div class="container">
  <fgbcl-badge-overview-list></fgbcl-badge-overview-list>
</div>
