<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container px-0">
    <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<fgb-account-nav></fgb-account-nav>

<div class="container text-center">
  <!-- Cards -->
  <ng-container *ifScreenSize="screenType.Desktop">
    <div class="row mt-4">
      <div class="col-lg-6">
        <fgb-member-card></fgb-member-card>
      </div>
      <div class="col-lg-6">
        <img alt="" class="rounded" [height]="195" [width]="456" src="assets/images/placeholder-widget.jpg" draggable="false" />
      </div>
    </div>
  </ng-container>
</div>

<div class="container">
  <fgb-worksheet-list></fgb-worksheet-list>
</div>

<!--Scorecard carousel-->
<fgbcl-scorecard-carousel [icon]="'chevron_right'" [showAllView]="true"></fgbcl-scorecard-carousel>

<!-- Announcements -->
<div class="container">
  <fgbcl-announcements></fgbcl-announcements>
</div>

<!-- TO BE USED FOR TESTING SCORECARDS -->
<ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.voucher'">
  <fgb-vouchers></fgb-vouchers>
</ng-container>
