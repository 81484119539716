<div *ngIf="purchasedItem; else noPurchase" class="container">
  <div class="d-block d-lg-flex shadow border-0 rounded overflow-hidden mt-lg-5 mt-3">
    <!-- Details -->
    <div class="item-details d-flex flex-column justify-content-between flex-grow-1 flex-shrink-1 p-3">
      <div>
        <div class="text-success mb-0 fw-bold text-uppercase font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.success' | translate
            }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{ 'events.purchase.success' | translate
            }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Product'">{{ 'products.purchase.success' | translate
            }}</ng-container>
        </div>
        <hr class="mt-4 mx-5 mb-2" />
        <p class="mb-0 text-black font-size-14 text-center">
          <ng-container *ngIf="marketplaceItemType == 'Marketplace'">{{
            'marketplace.purchase.email.confirmation' | translate
            }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Event'">{{
            'events.purchase.email.confirmation' | translate
            }}</ng-container>
          <ng-container *ngIf="marketplaceItemType == 'Product'">{{
            'products.purchase.email.confirmation' | translate
            }}</ng-container>
        </p>
        <div class="row my-5">
          <div class="col-lg-1 col-2 d-flex justify-content-left">
            <img class="purchase-success-image shadow rounded"
              src="{{ purchasedItem.ItemImageURL | contentImage: '7':'marketplace' }}"
              alt="{{ purchasedItem.ImageURL }}" [useDefault]="true" loyaltyType="marketplace" draggable="false" />
          </div>
          <div class="col-lg-10 col-10">
            <div class="pl-lg-3 ps-1">
              <div class="font-size-12 mb-1">{{ purchasedItem.DisplayName }}</div>
              <div class="row">
                <!--Attributes-->
                <ng-container *ngFor="let attribute of attributes">
                  <div class="col-12 mt-1 mb-2">
                    <div class="font-size-12 mb-0">
                      <span class="fw-bold">{{ attribute.AttributeName }}</span>
                      <span> {{ attribute.ValueName }}</span>
                    </div>
                  </div>
                </ng-container>
                <!--Custom Fields-->
                <ng-container *ngFor="let customfield of customFields">
                  <div class="col-12 mb-2">
                    <div class="font-size-12 mb-0">
                      <span class="fw-bold">{{ customfield.DisplayName }}:</span>
                      <span> {{ customfield.Value }}</span>
                    </div>
                  </div>
                </ng-container>
                <!--Quantity-->
                <div class="col-4 col-lg-3">
                  <div class="font-size-12 mb-0">
                    <span class="fw-bold pe-2">{{ 'marketplace.quantity' | translate }}</span>
                    <span>{{ quantity }}</span>
                  </div>
                </div>
                <!--Price-->
                <div class="col-8 col-lg-5" *ngIf="purchasedItem.EcashValue <= 0">
                  <div class="font-size-12 mb-0">
                    <span class="fw-bold pe-2">{{ 'marketplace.points.used' | translate }}</span>
                    <span>{{ price | number }}</span>
                  </div>
                </div>
                <!--Ecash-->
                <div class="col-8 col-lg-5" *ngIf="purchasedItem.EcashValue > 0">
                  <div class="font-size-12 mb-0 d-flex align-items-center">
                    <span class="fw-bold pe-2">{{ 'marketplace.ecash.used' | translate }}</span>

                    <span>{{ (quantity * purchasedItem.EcashValue) / 100 | FortressCurrencyPipe }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mx-auto mb-2 border-0" *ngIf="purchasedItem.ProcessProviderId == ProcessProvider.Download">
        <a class="d-flex align-items-center justify-content-center btn btn-primary confirm-btn fw-bold"
          (click)="downloadPurchasedItem(purchasedItemLogId)">
          <span class="icon material-icons me-1 purchased-download-icon">download_for_offline</span>
          <span class="font-size-14">{{ 'download.rewards.item' | translate }}</span>
        </a>
      </div>

      <div class="d-flex justify-content-between border-0">
        <a class="btn btn-primary confirm-btn fw-bold" [routerLink]="['/rewards/marketplace']">{{
          'rewards.back.to.rewards' | translate
          }}</a>
        <a class="btn btn-wallet text-white confirm-btn fw-bold" [routerLink]="['/rewards/wallet']">{{
          'rewards.back.to.wallet' | translate
          }}</a>
      </div>
    </div>
  </div>
</div>

<ng-template #noPurchase>
  <div class="text-center m-5">{{ 'no.purchased.item.found' | translate }}</div>
</ng-template>