<ng-container *ngIf="worksheets$ | withLoading | async as worksheets">
  <ng-container *ngIf="worksheets.data">
    <div *ngFor="let worksheetGroup of worksheets.data">
      <ng-container *ngIf="worksheetGroup.Items?.length > 0">
        <h2 class="card-header fw-bold border-0 px-2 pb-0 pt-2">
          {{ 'prize.draw.title' | translate }}
        </h2>
        <div [class.row]="isList">
          <div *ngFor="let item of worksheetGroup.Items" [class.col-lg-4]="isList" [class.col-md-6]="isList">
            <div>
              <fgb-worksheet-item [worksheetItem]="item" [worksheetId]="worksheetGroup.WorksheetId"></fgb-worksheet-item>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- No Data available -->
  <ng-container *ngIf="worksheets.isEmpty"> <p>No worksheet available</p></ng-container>

  <!-- Error -->
  <ng-container *ngIf="worksheets.error">
    <p>Error {{ worksheets.error }}</p>
  </ng-container>

  <!-- Loading -->
  <ng-container *ngIf="worksheets.isLoading">
    <div class="w-100">
      <fgbcl-loading></fgbcl-loading>
    </div>
  </ng-container>
</ng-container>
