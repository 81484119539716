<div class="card rounded border">
  <div *ngIf="member$ | async as member">
    <div class="">
      <ng-container *ngIf="card$ | async as card">
        <label class="mb-0 font-size-12">{{ 'account.details.membership.number' | translate }}</label>
        <div class="d-flex pb-4 m-0">
          <span class="font-size-12">{{ card.MemberSince }}</span>
        </div>
      </ng-container>
      <label class="mb-0 font-size-12">{{ 'account.details.dob' | translate }}</label>
      <div class="account-details-value d-flex align-items-center bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">date_range</span>
        <span class="ms-3 font-size-12">{{ member.DOB | date : 'MMM d, yyy' }}</span>
      </div>
    </div>
    <div>
      <label class="mb-0 mt-4 font-size-12">{{ 'account.details.email' | translate }}</label>
      <div class="account-details-value d-flex align-items-center bg-muted p-2 m-0 mt-1 rounded">
        <span class="icon material-icons" aria-hidden="true">email</span>
        <span class="ms-3 font-size-12">{{ member.EmailAddress1 }}</span>
      </div>
    </div>
  </div>
</div>
