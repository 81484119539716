<div class="full-page w-100 d-md-flex d-block px-lg-0 bg-secondary m-0">
  <div class="login-page-div d-flex flex-column justify-content-lg-center justify-content-evenly align-items-center w-100 h-100">
    <div class="login-page-logo d-flex align-items-center">
      <img alt="" class="logo" src="assets/images/logo.png" draggable="false" />
    </div>
    <div class="login-page-details mt-5 d-flex flex-column justify-content-center align-items-center bg-white rounded-4">
      <div class="panel-text login-text font-size-14 text-center d-flex align-items-center">
        {{ 'login.page.description' | translate }}
      </div>
      <fgb-tm-user-login></fgb-tm-user-login>

      <ng-container *ngIf="errorCode$ | async as errorCode">
        <div
          class="login-text-info text-black text-center mt-5 pb-5"
          [innerHTML]="errorCode | translate | markdownTranslate"
        ></div>
      </ng-container>
    </div>
  </div>
</div>
