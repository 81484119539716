<ng-container *ngIf="transactions$ | async as transactions">
  <ng-container *ngIf="transactions$ | async as transactions">
    <div *ngFor="let day of transactions | keyvalue : keepOrder">
      <h5 class="transaction-date mt-4 mb-3 text-primary">{{ day.key }}</h5>
      <div class="mb-0 mb-lg-5">
        <div class="card my-3 py-2 px-4 border-0" *ngFor="let transaction of day.value">
          <div class="row">
            <div class="col-8 text-start">
              <div class="fw-bold text-black transaction-text">
                {{ transaction.TransactionDescription }}
              </div>

              <div class="transaction-subtext">
                {{ transaction.TransactionDateUTC | UtcToLocaltimePipe : 'hh:mm a' | uppercase }}
              </div>
            </div>
            <div class="col-4 text-end pt-2">
              <div
                class="fw-bold transaction-text text-capitalize"
                [ngClass]="transaction.Amount >= 0 ? 'text-success' : 'text-danger'"
              >
                {{ transaction.Amount >= 0 ? '+' : ''
                }}{{ transaction.Amount | FortressCurrencyPipe : (transactionType === transactionTypes.loyalty ? 'PTS' : 'USD') }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.loyalty">
      <div class="mt-3 placeholder-box font-size-12 text-center border rounded-4 bg-white text-black py-3">
        <p class="text-black text-center font-size-12">{{ 'account.activity.placeholder.list.description' | translate }}</p>
      </div>
    </ng-container>
    <ng-container *ngIf="(!transactions || !hasTransactions) && transactionType === transactionTypes.ecash">
      <div class="mt-3 placeholder-box font-size-12 text-center border rounded-4 bg-white text-black py-3">
        <p class="text-black text-center font-size-12 mt-3">{{ 'account.activity.placeholder.list.description' | translate }}</p>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
<div *ngIf="loading" class="mt-4">
  <fgbcl-loading></fgbcl-loading>
</div>
