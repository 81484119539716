<div class="mobile-nav-menu d-flex position-fixed flex-column d-lg-none">
  <!-- NAV BAR -->
  <div class="mobile-nav-bar d-flex justify-content-between">
    <a class="nav-item fw-bold brand p-1" [routerLink]="['/']" routerLinkActive="router-link-active">
      <label id="homeLink" class="accessibility-only">Go To Home Page</label>
      <img alt="" class="home-logo" src="assets/images/logo.png" draggable="false" />
    </a>

    <div class="d-flex align-items-center">
      <!--Notifications-->
      <div class="nav-item">
        <fgb-notification-button></fgb-notification-button>
      </div>
      <button
        attr.aria-label="{{ 'nav.toggle.screenreader' | translate }}"
        class="nav-item fw-bold navbar-toggler m-0 px-2"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon material-icons material-mobile-nav">menu</span>
      </button>
    </div>
  </div>

  <!-- NAV SCREEN -->
  <div [ngbCollapse]="isCollapsed" class="mobile-nav-menu-dropdown">
    <div class="d-flex flex-column mobile-nav-menu-content">
      <!-- Close Button -->
      <button
        class="close-button position-absolute border-0"
        attr.aria-label="{{ 'modal.close' | translate }}"
        (click)="isCollapsed = !isCollapsed"
      >
        <span class="icon material-icons fw-bold">close</span>
      </button>
      <div class="mx-2 my-2">
        <img alt="" class="logo" src="assets/images/logo.png" draggable="false" />
      </div>

      <!-- BarCode -->
      <div class="container px-0">
        <fgb-barcode [isWalletPage]="false" [showBarcode]="false"></fgb-barcode>
      </div>

      <ng-template #loadingRef>
        <fgbcl-loading></fgbcl-loading>
      </ng-template>

      <!-- Nav Buttons -->
      <div class="mobile-nav-button-container">
        <a
          class="nav-item large-item"
          [routerLink]="['/']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.home' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/account']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.profile' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/challenges']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.challenges' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a
          class="nav-item"
          [routerLink]="['/rewards/raffles']"
          [routerLinkActive]="'active'"
          [routerLinkActiveOptions]="{ exact: true }"
        >
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.prize.draws' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.payment'">
          <a
            class="nav-item"
            [routerLink]="['/payment']"
            [routerLinkActive]="'active'"
            [routerLinkActiveOptions]="{ exact: true }"
          >
            <div class="nav-item-title-div">
              <div class="nav-text">{{ 'nav.payment' | translate }}</div>
            </div>
            <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
          </a>
        </ng-container>

        <a class="nav-item" [routerLink]="['/terms']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.terms' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item" [routerLink]="['/faq']" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{ exact: true }">
          <div class="nav-item-title-div">
            <div class="nav-text">{{ 'nav.faq' | translate }}</div>
          </div>
          <div><span class="icon material-icons mx-0" aria-hidden="true">chevron_right</span></div>
        </a>

        <a class="nav-item logout-link-div" (click)="logout()">
          <div class="nav-text">{{ 'nav.log.out' | translate }}</div>
        </a>
      </div>
    </div>
  </div>
</div>
