<ng-container *ifScreenSize="screenType.Desktop">
  <div class="banner" role="banner"></div>
</ng-container>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container px-0">
    <fgb-barcode [isWalletPage]="false" [showBarcode]="true"></fgb-barcode>
  </div>
</ng-container>

<div class="container mt-4">
  <div class="row">
    <ng-container *ifScreenSize="screenType.Desktop">
      <div class="col-12 col-lg-6">
        <h5 class="text-start mb-2 mt-lg-0">{{ 'account.member.card.title' | translate }}</h5>
        <fgb-member-card [showSeating]="true"></fgb-member-card>
      </div>
    </ng-container>

    <div class="col-12 col-lg-6">
      <h5 class="text-start mb-2 my-0 mt-lg-0">{{ 'account.details.title' | translate }}</h5>
      <fgb-account-details></fgb-account-details>
    </div>
  </div>
</div>
