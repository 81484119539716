import { Component, OnInit, Input } from '@angular/core';
import { MixedLoyaltyQuery, RewardsWalletItem, ScorecardService } from '@fgb/core';
import { ScreenType } from '@fgb/portal-component-library/src/lib/directives';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'fgb-wallet-page',
  templateUrl: './wallet-page.component.html',
  styleUrls: ['./wallet-page.component.scss'],
})
export class WalletPageComponent implements OnInit {
  @Input() walletItems: RewardsWalletItem[];
  screenType = ScreenType;

  constructor(private mixedLoyaltyQuery: MixedLoyaltyQuery, private scorecardService: ScorecardService) {}

  async ngOnInit() {
    await this.mixedLoyaltyQuery.walletFetch();
    firstValueFrom(this.scorecardService.fetchScorecards());
  }
}
