<div class="barcode-component w-100 d-block d-lg-none px-3 pb-2" [ngClass]="season ? 'bg-primary' : 'bg-secondary'">
  <div class="border-0" *ngIf="barcode$ | async as barcode">
    <div class="p-0 flex-column align-items-center d-flex flex-wrap justify-content-around pt-2">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="member-details" [ngClass]="season ? 'text-secondary' : 'text-white'">
          <div class="fw-bold card-name" *ngIf="memberDetails$ | async as memberDetails">
            {{ memberDetails.FirstName }} {{ memberDetails.Surname }}
          </div>
          <ng-container *ngIf="card$ | async as memberCard">
            <div class="font-size-12">
              <div>{{ memberCard.ProductCode }} {{ 'product.code.description' | translate }}</div>
            </div>
          </ng-container>
        </div>
        <div class="wallet-page-button-div" *ngIf="!isWalletPage">
          <button
            class="btn wallet-page-button rounded-4 d-flex align-items-center"
            [routerLink]="walletLink"
            [ngClass]="season ? 'btn-secondary text-white' : 'bg-primary text-black'"
          >
            <span class="font-size-14 fw-bold">{{ 'barcode.wallet.page.button.text' | translate }}</span>
          </button>
        </div>
      </div>
      <!--Barcode-->
      <ng-container *ngIf="showBarcode">
        <div class="qr py-0 bg-white w-100 rounded-4 d-flex flex-column align-items-center justify-content-center mb-2 mt-2">
          <qrcode [qrdata]="barcode.Barcode" [size]="256" [level]="'M'" [usesvg]="true"></qrcode>
          <p class="font-size-12 mb-1">{{ 'scan.member.barcode' | translate }}</p>
        </div>
      </ng-container>
      <!--Member Details-->
      <div class="discount-details pe-2 mt-2 w-100 rounded-4 bg-white text-black">
        <div class="mb-1 member-card-footer pt-2">
          <ng-container *ngIf="card$ | async as memberCard">
            <div class="d-flex align-items-center">
              <div class="text-center pe-4">
                <label class="font-size-14 fw-bold mb-1">{{ memberCard.RetailDiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.retail.discount' | translate }}</div>
              </div>

              <div class="border-right-divider"></div>

              <div class="text-center px-4">
                <label class="font-size-14 fw-bold mb-1">{{ memberCard.DiscountLevel }}%</label>
                <div class="font-size-12">{{ 'member.card.discount' | translate }}</div>
              </div>

              <ng-container *fgbWebsiteContentFeatureFlag="'feature.flag.payment'">
                <div class="border-right-divider"></div>

                <div class="text-start ps-4">
                  <label class="font-size-14 mb-0"> {{ eCash$ | async | FortressCurrencyPipe }}</label>
                  <div class="font-size-12">{{ 'member.card.payment' | translate }}</div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
    <fgb-ecash-button></fgb-ecash-button>
  </div>
</div>
