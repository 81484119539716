<div class="d-none d-lg-block">
  <div class="banner" role="banner"></div>
</div>

<ng-container *ifScreenSize="screenType.Mobile">
  <div class="container px-0 mb-3">
    <fgb-barcode [isWalletPage]="true" [showBarcode]="true"></fgb-barcode>
  </div>
</ng-container>

<!--Account Nav-->
<div class="d-lg-block d-none">
  <fgb-account-nav></fgb-account-nav>
</div>

<!-- Wallet -->
<ng-container *ngIf="walletItems?.length > 0; else noWalletItems">
  <div class="wallet-redeem-carousel pt-lg-4">
    <div class="container">
      <div class="wallet-redeem-carousel">
        <fgbcl-rewards-wallet-redeem-list [unRedeemedItems]="walletItems"></fgbcl-rewards-wallet-redeem-list>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noWalletItems>
  <div class="container pt-lg-4">
    <h5 class="no-wallet-items mb-3 fw-bold">{{ 'rewards.wallet.no.items' | translate }}</h5>
    <div class="bg-white text-black border-disabled rounded">
      <div class="no-wallet-items-description font-size-12 text-center text-black py-3">
        {{ 'rewards.wallet.no.items.description' | translate }}
      </div>
    </div>
  </div>
</ng-template>

<div class="container mt-3 text-center">
  <a class="font-size-14 claimed-text fw-bold" [routerLink]="['/rewards/claimed']">
    {{ 'rewards.wallet.view.claimed' | translate }}
  </a>
</div>
