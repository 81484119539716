<div class="full-page w-100 d-md-flex d-block px-lg-0 bg-secondary m-0">
  <div class="login-page-div d-flex flex-column justify-content-lg-center justify-content-evenly align-items-center w-100 h-100">
    <div class="login-page-logo d-flex align-items-center pb-5">
      <img alt="" class="logo" src="assets/images/logo.png" draggable="false" />
    </div>
    <div class="login-page-details mt-5 d-flex flex-column justify-content-center align-items-center bg-white rounded-4">
      <div class="login-container text-center">
        <div class="icon material-icons text-secondary">engineering</div>
        <h5 class="my-1 text-capitalize text-secondary">{{ 'maintenance.page.title' | translate }}</h5>
        <div class="font-size-14 mx-4">{{ 'maintenance.page.details' | translate }}</div>
      </div>
    </div>
  </div>
</div>
